/* do not group these rules */
*::-webkit-input-placeholder {
    color: darken($nav-text-color, 10);
}

*:-moz-placeholder {
    /* FF 4-18 */
    color: darken($nav-text-color, 10);
}

*::-moz-placeholder {
    /* FF 19+ */
    color: darken($nav-text-color, 10);
}

*:-ms-input-placeholder {
    /* IE 10+ */
    color: darken($nav-text-color, 10);
}

nav {
    background: $nav-background-color;
    color: $nav-text-color;
    display: none;

    &.permanentTopNav {
        display: block;

        &.sticky {
            position: fixed;
            width: 100%;
            z-index: 60;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25em 0;
        min-height: 65px;
    }

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            display: flex;

            a {
                margin: 0;
                text-align: center;
                padding: 1em 1.4em;
                display: flex;

                &:hover {
                    color: $bubble-hover-color;
                }
            }
        }
    }

    // Algolia-Search
    .search {
        margin-left: auto;
        // width: 45%;
        min-width: 300px;
        position: relative;

        input {
            font-family: $content-font;
        }

        i {
            position: absolute;
            left: 1em;
            top: 10px;
            z-index: 40;
        }

        input {
            margin: 0;
            background: transparent;
            border: none;
            color: $nav-text-color;
            padding-left: 2.75em;
            width: 100%;
            background-color: lighten($nav-background-color, 5);

            &:hover,
            &:focus {
                background-color: lighten($nav-background-color, 10);
                border: none;
            }
        }

        .algolia-autocomplete {
            width: 100%;
            color: $algolia-search-box-color;

            .aa-dropdown-menu {
                width: 100%;
                overflow: hidden;
                border-radius: 1em;
                padding: 0.5em;

                .empty {
                    padding: 0.5em 1em;
                    display: block;
                }

                .branding {
                    font-size: 0.75em;
                    text-align: right;
                    padding: 0.5em 1em;
                    border-radius: 0.5em;
                    background-color: rgba($page-background-color,0.6);

                    img {
                        width: 3.5em;
                        position: relative;
                        // top: 0.1em;
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0.5em;
                        background: rgba(#fff,0.5);
                    }
                }
            }

            .aa-suggestion {
                span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $algolia-search-box-background-color;

                    &:hover,
                    &:focus {
                        background-color: darken($algolia-search-box-background-color, 5);
                    }

                    .title {
                        max-width: 315px;
                    }

                    &.entry.category,
                    &.entry.tag,
                    &.entry.author {
                        .title {
                            text-transform: capitalize;
                        }
                    }

                    .fa {
                        font-size: 1em;
                        color: $algolia-search-box-icon-color;
                        text-align: right;
                    }
                }
            }
        }

        .aa-input-container {
            display: inline-block;
            position: relative;
        }

        .aa-input-search {
            width: 300px;
            padding: 12px 28px 12px 12px;
            border: 1px solid $algolia-border-color;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .aa-input-search::-webkit-search-decoration,
        .aa-input-search::-webkit-search-cancel-button,
        .aa-input-search::-webkit-search-results-button,
        .aa-input-search::-webkit-search-results-decoration {
            display: none;
        }

        .aa-input-icon {
            height: 16px;
            width: 16px;
            position: absolute;
            top: 50%;
            right: 16px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            fill: $algolia-border-color;
            pointer-events: none;
        }

        .aa-dropdown-menu {
            background-color: $algolia-search-box-background-color;
            border: 1px solid rgba($algolia-border-color, 0.2);
            min-width: 300px;
            margin-top: 10px;
            box-sizing: border-box;
        }

        .aa-suggestion {
            padding: 6px 12px;
            cursor: pointer;
        }

        .aa-suggestion + .aa-suggestion {
            border-top: 1px solid rgba($algolia-border-color, 0.2);
        }

        .aa-suggestions-category {
            border-bottom: 1px solid rgba($algolia-border-color, 0.2);
            border-top: 1px solid rgba($algolia-border-color, 0.2);
            padding: 6px 12px;
        }
    }
}
