.paginator {
    display: flex;
    margin-left: 100px;
    margin-top: 75px;
    padding-bottom: 1em;

    .fa {
        margin: 0 0.25em;
    }

    a {
        border-radius: 4px;
        padding: 0.8em 1.4em;
        text-decoration: none;
        font-weight: bold;
        background-color: $article-background-color;
        color: $text-color;

        &:hover {
            background-color: $highlight-color;
            transition: all 0.3s ease-in-out;
        }

        &.older {
            margin-right: auto;
        }

        &.newer {
            margin-left: auto;
        }
    }
}
