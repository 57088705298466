// The variables below are processed in './layouts/partials/css.html'
// when it's executed as template by Hugo pipes.

// Width of the main content area where the the actual content's width is $site-width - 100px
$site-width: 800px;

$headline-font: 'Comfortaa',sans-serif;
$content-font: 'Open Sans',sans-serif;
$article-footer-font: 'Fira Code',monospace;
$code-block-font: 'Fira Code',monospace;

$page-background-color: #f1f1f1;
$base-color: #1d1f38;
$special-color: #2D3642;
$highlight-color: #5c8b59;
$text-color: #222;
$blockquote-background-color: #f6f6f6;

$nav-background-color: $special-color;
$nav-text-color: $page-background-color;
$algolia-search-box-color: #444;
$algolia-search-box-icon-color: #888;
$algolia-search-box-background-color: #fafafa;
$algolia-border-color: #e4e4e4;

$header-text-color: $page-background-color;
$logo-color: darken($page-background-color, 1);
$bubble-color: #fff;
$bubble-background-color: #ccc;
$bubble-hover-color: $highlight-color;
$connection-line-color: rgba(#000,0.05);
$connection-dot-color: #ddd;

$article-background-color: #fff;
$meta-text-color: #999;
$meta-border-color: #eee;
$continue-reading-hover-color: $meta-text-color;

$footer-border-color: #eee;
$footer-background-color: rgba(#ffffff, 80);
$footer-headline-color: #444;

@mixin featuredImage() {
  .featured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.25;
  }
}

// Dependencies
@import '../../assets/sass/_vendor/normalize.scss';
@import '../../assets/sass/_vendor/skeleton.scss';
@import '../../assets/sass/_vendor/flexslider.scss';
@import '../../assets/sass/_vendor/dimbox.scss';
@import '../../assets/sass/_vendor/tooltipster.scss';
@import '../../assets/sass/_vendor/tooltipster-sideTip-light.scss';
@import 'fontface';

// Base theme styling
@import 'bilberry-hugo-theme';

.dark-mode {
$page-background-color: #333;
$base-color: #1d1f38;
$special-color: #2D3642;
$highlight-color: saturate(#5c8b59,20);
$text-color: darken(invert(#222), 20);
$blockquote-background-color: rgba(99,110,110,0.2);

$nav-background-color: $special-color;
$nav-text-color: darken(#f1f1f1,20);
$algolia-search-box-color: $nav-text-color;
$algolia-search-box-icon-color: #888;
$algolia-search-box-background-color: #222;
$algolia-border-color: #e4e4e4;

$header-text-color: $nav-text-color;
$logo-color: darken(#f1f1f1, 60);
$bubble-color: $logo-color;
$bubble-background-color: #373737;
$bubble-hover-color: $highlight-color;
$connection-line-color: #444;
$connection-dot-color: #444;

$article-background-color: #222;
$meta-text-color: darken(#f1f1f1,40);
$meta-border-color: #545454;
$continue-reading-hover-color: $meta-text-color;

$footer-border-color: #444;
$footer-background-color: #222;
$footer-headline-color: darken(invert(#444),5);

@mixin featuredImage() {
  .featured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.25;
  }
}

// Dependencies
@import '../../assets/sass/_vendor/normalize.scss';
@import '../../assets/sass/_vendor/skeleton.scss';
@import '../../assets/sass/_vendor/flexslider.scss';
@import '../../assets/sass/_vendor/dimbox.scss';
@import '../../assets/sass/_vendor/tooltipster.scss';
@import '../../assets/sass/_vendor/tooltipster-sideTip-light.scss';
@import 'fontface';

// Base theme styling
@import 'bilberry-hugo-theme';
}
