@import '../../assets/sass/_vendor/fontawesome/brands';
@import '../../assets/sass/_vendor/fontawesome/regular';
@import '../../assets/sass/_vendor/fontawesome/solid';
@import '../../assets/sass/_vendor/fontawesome/fontawesome';

/* comfortaa-regular - greek_latin-ext_vietnamese_cyrillic-ext */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-regular.svg#Comfortaa') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* comfortaa-700 - greek_latin-ext_vietnamese_cyrillic-ext */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/comfortaa-v28-greek_latin-ext_vietnamese_cyrillic-ext-700.svg#Comfortaa') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* open-sans-regular - latin-ext_vietnamese_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* open-sans-italic - latin-ext_vietnamese_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* open-sans-700 - latin-ext_vietnamese_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* open-sans-700italic - latin-ext_vietnamese_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v17-latin-ext_vietnamese_cyrillic-ext_greek-ext-700italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* fira-code-regular - latin-ext_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-regular.svg#FiraCode') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* fira-code-700 - latin-ext_cyrillic-ext_greek-ext */
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/fira-code-v8-latin-ext_cyrillic-ext_greek-ext-700.svg#FiraCode') format('svg');
  /* Legacy iOS */
  font-display: swap;
}
