.main {
    margin-bottom: 5em;
    min-height: 50vh;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50px;
        bottom: 50px;
        width: 2px;
        height: 100%;
        background: $connection-line-color;
        z-index: 1;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 41px;
        bottom: 32px;
        height: 20px;
        width: 20px;
        background: $connection-dot-color;
        border-radius: 999px;
        z-index: 10;
    }

    .article-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-top: 50px;
        min-height: 90px;
        position: relative;

        // Move the left circle all the way down to the end of the article-wrapper
        &.single {
            padding-bottom: 2.5em;
        }

        .bubble {
            position: absolute;
            left: 25px;
            top: 70px;
            background-color: $bubble-background-color;
            border: 3px solid $bubble-color;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 10;
            text-decoration: none;
            color: $bubble-color;
            transition: all 0.3s ease-in-out;

            .fa {
                font-size: 15pt;
            }

            &:hover {
                background-color: $highlight-color;
            }
        }
    }
}
